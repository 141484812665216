
import axios from 'axios';
import { API_URL } from "../../configs/siteConfig";
import Swal from 'sweetalert2'

const _api = {
  myBid: async function (id,token) {
    return axios.get(API_URL+'/api/Bidder?auctionid='+id, {
       headers: { 'content-type': 'application/x-www-form-urlencoded' ,
       'Authorization': 'Bearer ' + token,
     },
     })
     .then(function (response) {
      return response?.data;
     })
     .catch(function (error) {
       return error?.response.data;
     });
  },

  myBidByType: async function (payload) {
    return axios.get(API_URL+'/Bidder/GetBySelectedType?auctionid='+ payload.auctionId + "&selectedType=" + payload.selectedType, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' ,
      'Authorization': 'Bearer ' + payload.token,
    },
   })
   .then(function (response) {
    return response?.data;
   })
   .catch(function (error) {
     return error?.response.data;
   });
  },

  myBidReceipt: async function (payload) {
    return axios.get(API_URL+'/Bidder/DownloadReceipt?itemId=' + payload.itemId + '&auctionid='+ payload.auctionId + '&isDonation=' + payload.isDonation, {
      responseType: 'arraybuffer',
      headers: { 'content-type': 'application/x-www-form-urlencoded' ,
      'Authorization': 'Bearer ' + payload.token,
    },
   })
   .then(function (response) {

    if(response.status === 200 && payload.afterAction)
    {
      payload.afterAction(response);
    }

    return response?.data;
   })
   .catch(function (error) {
     return error?.response.data;
   });
  },

  addBid: async function (data,token) {
    return axios.post(API_URL+'Bid/Post', data,{
       headers: { 'content-type': 'application/json' ,
       'Authorization': 'Bearer ' + token,
     },
     })
     .then(function (response) {
      var onCloseOfModal = ()=> {
        window.location.reload();
      };  

       if(response.data.Status){
        
        Swal.fire({
          showClass: {
            popup: 'animated fadeIn faster'
        },
        hideClass: {
            popup: 'animated fadeOut faster'
        },
          icon: 'success',
          iconColor:"var(--accentColor)",
          title: 'Success!',
          text: response.data.ErrMessage,
          showCloseButton: true,
          showConfirmButton:false,
        }).then(onCloseOfModal);
       }else{
      if(response.data.ErrMessage==="Purchase failed"){
      Swal.fire({
        showClass: {
          popup: 'animated fadeIn faster'
      },
      hideClass: {
          popup: 'animated fadeOut faster'
      },
        icon: 'error',
        iconColor:"var(--accentColor)",
        title: 'Oops...',
        text: response.data.ErrMessage,
        showCloseButton: true,
        showConfirmButton:false,
      }).then(onCloseOfModal);
    }else{
      Swal.fire({
        showClass: {
          popup: 'animated fadeIn faster'
      },
      hideClass: {
          popup: 'animated fadeOut faster'
      },
        icon: 'info',
        iconColor:"var(--accentColor)",
        title: 'Sorry!',
        text: response.data.ErrMessage,
        showCloseButton: true,
        showConfirmButton:false,
      }).then(onCloseOfModal);
    } 
    }
      return response?.data;
     })
     .catch(function (error) {
       return error?.response.data;
     });
 },
 cartItems: async function (id,token) {
  return axios.get(API_URL+'Bidder/CheckOut?auctionid='+id,{
     headers: { 'content-type': 'application/x-www-form-urlencoded' ,
     'Authorization': 'Bearer ' + token,
   },
   })
   .then(function (response) {
    return response?.data;
   })
   .catch(function (error) {
     return error?.response.data;
   });
},
 checkout: async function (id,token) {
  return axios.get(API_URL+'Bidder/CheckOut?auctionid='+id,{
     headers: { 'content-type': 'application/x-www-form-urlencoded' ,
     'Authorization': 'Bearer ' + token,
   },
   })
   .then(function (response) {
    return response?.data;
   })
   .catch(function (error) {
     return error?.response.data;
   });
},
checkoutComplete: async function (data,token) {
  return axios.post(API_URL+'Bidder/CompleteCheckout', data,{
     headers: { 'content-type': 'application/json' ,
     'Authorization': 'Bearer ' + token,
   },
   })
   .then(function (response) {
    var redirect = () => {      
      window.location.href = '/';
    };

    if(response.data.Result){
      Swal.fire({
        showClass: {
          popup: 'animated fadeIn faster'
      },
      hideClass: {
          popup: 'animated fadeOut faster'
      },
        icon: 'success',
        iconColor:"var(--accentColor)",
        title: 'Success!',
        text: response.data.Message,
        showCloseButton: true,
        showConfirmButton:false        
      }).then(redirect);
     }else{

    Swal.fire({
      showClass: {
        popup: 'animated fadeIn faster'
    },
    hideClass: {
        popup: 'animated fadeOut faster'
    },
      icon: 'error',
      iconColor:"var(--accentColor)",
      title: 'Error!',
      text: response.data.Message,
      showCloseButton: true,
      showConfirmButton:false,
    })
  
}
    return response?.data;
    
   })
   .catch(function (error) {
     return error?.response.data;
   });
}
}
export default _api;
