import { all, fork, takeLatest, delay, put } from "redux-saga/effects";
import { _types } from "../types";
import _api from "./api";

const { SIGNIN, REGISTER ,AUCTION,BIDDER_UPDATE, BIDDER, SIGNOUT,FORGET_PASSWORD,RESET_PASSWORD, REQ, CLEAR, STATE, SUCCESS, FAILD } = _types;

export function* authSaga() {
  // Sign In
  yield takeLatest(SIGNIN + REQ, function* ({ payload }) {
    const response = yield _api.LoginUser({
      ...payload,
      grant_type: "password",
    });
    if (response?.error) {
      yield put({ type: SIGNIN + FAILD, payload: response.error_description });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: SIGNIN + SUCCESS,
        payload: response,
      });
    }
  });

  // Sign Out
  yield takeLatest(SIGNOUT + REQ, function* () {
    yield put({ type: SIGNOUT });
  });

  // Register User
  yield takeLatest(REGISTER + REQ, function* ({ payload }) {
    const response = yield _api.RegisterUser({
      ...payload,
    });
    if (response?.error) {
      yield put({ type: REGISTER + FAILD, payload: response.error_description });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      if(response.Result){
      yield put({
        type: REGISTER + SUCCESS,
        payload: response,
      });
    }else{
      yield put({ type: REGISTER + FAILD, payload: response.Message });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    }

    }
  });

  // Auction
  yield takeLatest(AUCTION + REQ, function* ({ payload }) {
    const response = yield _api.getAuctionID(payload);
    // console.log(response)
      if(response){
      yield put({
        type: AUCTION + SUCCESS,
        payload: response,
      });
      }
  });

// Bidder
  yield takeLatest(BIDDER + REQ, function* ({ payload }) {
    const response = yield _api.UserInfo(payload.id,payload.token);
    // console.log(response)
      if(response){
      yield put({
        type: BIDDER + SUCCESS,
        payload: response,
      });
      }
  });
  
// Bidder Update
yield takeLatest(BIDDER_UPDATE + REQ, function* ({ payload }) {
  const response = yield _api.updateUser(payload,payload.token);
    //console.log(response)
    if(response) {
      yield put({
        type: BIDDER_UPDATE + SUCCESS,
        payload: response,
      });
    }
});


// Forget Password
yield takeLatest( FORGET_PASSWORD + REQ, function* ({ payload }) {
  const response = yield _api.forgetPassword(payload.data,payload.token);
    if(response){
      if(response.Success){
    yield put({
      type: FORGET_PASSWORD + SUCCESS,
      payload: response,
    });
    yield delay(3000);
    yield put({ type: STATE + CLEAR });

  }else{
    yield put({
      type: FORGET_PASSWORD + FAILD,
      payload: response.Message,
    });
    yield delay(3000);
    yield put({ type: STATE + CLEAR });
  }
    }
});


// Reset Password
yield takeLatest( RESET_PASSWORD + REQ, function* ({ payload }) {
  const response = yield _api.resetPassword(payload,"");
  // console.log(response)
    if(response){
      if(response.Success){
        yield put({
          type: RESET_PASSWORD + SUCCESS,
          payload: response,
        });
        
      }else{
        yield put({
          type: RESET_PASSWORD + FAILD,
          payload: response.Message,
        });
        yield delay(3000);
        yield put({ type: STATE + CLEAR });
      }
    }
});
}


export default function* rootSaga() {
  yield all([fork(authSaga)]);
}
