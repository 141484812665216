import axios from "axios";

import { API_URL } from "../../configs/siteConfig";
// import SuperFetch from "../../configs/superFetch";
import qs from "qs";

const _api = {
  LoginUser: async function (data) {
    return axios
      .post(API_URL + "token", qs.stringify(data), {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },
  RegisterUser: async function (data) {
    return axios
      .post(API_URL + "register/Post", qs.stringify(data), {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },
  UserInfo: async function (id, token) {
    return axios
      .get(API_URL + "api/Bidder?auctionId=" + id, {
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },
  updateUser: async function (data, token) {
    return axios
      .post(API_URL + "Bidder/Update", qs.stringify(data), {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },
  forgetPassword: async function (data, token) {
    return axios
      .post(API_URL + "ForgotPassword/Post", qs.stringify(data), {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },
  resetPassword: async function (data, token) {
    return axios
      .post(API_URL + "ForgotPassword/Change", qs.stringify(data), {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },
  getAuctionID: async function (data) {
    return axios
      .get(API_URL + "api/Auction?shortCode=" + data.ShortCode)
      .then(function (response) {

        if(data.afterAction)
        {
          data.afterAction();
        }

        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },
};
export default _api;
