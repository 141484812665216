import axios from "axios";
import { API_URL } from "../../configs/siteConfig";
import qs from "qs";
import Swal from "sweetalert2";

const _api = {
  allDonationItem: async function (id, token) {
    return axios
      .get(API_URL + "Donation/All?auctionId=" + id, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },
  donationItem: async function (data, token) {
    return axios
      .get(
        `${API_URL}Donation/Get?auctionId=${data.auctionId}&itemId=${data.itemId}`,
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },
  donate: async function (data, token) {
    return axios
      .post(API_URL + "Donate/Post", qs.stringify(data), {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        if (response.data.Success) {
          Swal.fire({
            showClass: {
              popup: 'animated fadeIn faster'
          },
          hideClass: {
              popup: 'animated fadeOut faster'
          },
            icon: "success",
            iconColor: "var(--accentColor)",
            title: "Thank You!",
            text: 'Thank you for donating $'+data.Amount,
            showCloseButton: true,
            showConfirmButton: false,
          });
        }
        return response?.data;
      })
      .catch(function (error) {
        return error?.response.data;
      });
  },
};
export default _api;
