import { all, fork, takeLatest, delay, put } from "redux-saga/effects";
import { _types } from "../types";
import _api from "./api";

const { SILENT_ITEM,SILENT_ITEMS,SILENT_ITEMS_CATEGORY,SILENT_ITEM_CATEGORY, REQ, CLEAR, STATE, SUCCESS, FAILD, ADD_WATCHLIST, REMOVE_FROM_WATCHLIST } = _types;

export function* silentAuctionSaga() {

  // Silent Item
  yield takeLatest(SILENT_ITEM + REQ, function* ({ payload }) {
    const response = yield _api.silentItem(payload)
    if (!response) {
      yield put({ type: SILENT_ITEM + FAILD, payload: response.error_description });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: SILENT_ITEM + SUCCESS,
        payload: response,
      });
    }
  });

    // Silent Items
  yield takeLatest(SILENT_ITEMS + REQ, function* ({ payload }) {
    const response = yield _api.allSilentItems(payload.auctionId,payload.token)
    if (!response) {
      yield put({ type: SILENT_ITEMS + FAILD, payload: response.error_description });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: SILENT_ITEMS + SUCCESS,
        payload: response,
      });
    }
  });

  // Silent item Category
     yield takeLatest(SILENT_ITEM_CATEGORY + REQ, function* ({ payload }) {
      const response = yield _api.silentItemCategory(payload)
      if (!response) {
        yield put({ type: SILENT_ITEM_CATEGORY + FAILD, payload: response.error_description });
        yield delay(3000);
        yield put({ type: STATE + CLEAR });
      } else {
        yield put({
          type: SILENT_ITEM_CATEGORY + SUCCESS,
          payload: response,
        });
      }
    });

      // Silent item Categories
      yield takeLatest(SILENT_ITEMS_CATEGORY + REQ, function* ({ payload }) {
        const response = yield _api.allSilentItemCategories(payload.auctionId,"")
        if (!response) {
          yield put({ type: SILENT_ITEMS_CATEGORY + FAILD, payload: response.error_description });
          yield delay(3000);
          yield put({ type: STATE + CLEAR });
        } else {
          yield put({
            type: SILENT_ITEMS_CATEGORY + SUCCESS,
            payload: response,
          });
        }
      });

  //Add to Watchlist
  yield takeLatest(ADD_WATCHLIST + REQ, function* ({ payload }) {
    const response = yield _api.addToWatchlist(payload);
    if(response){
      if(response.Success){
        yield put({
          type: ADD_WATCHLIST + SUCCESS,
          payload: response,
        });
      } 
      else{
        yield put({
          type: ADD_WATCHLIST + FAILD,
          payload: response.Message,
        });

        yield delay(3000);
        yield put({ type: STATE + CLEAR });

      }
    }
  });

  //Remove from Watchlist
  yield takeLatest(REMOVE_FROM_WATCHLIST + REQ, function* ({ payload }) {
    const response = yield _api.removeFromWatchlist(payload);
    if(response){
     if(response.Success){
       yield put({
          type: REMOVE_FROM_WATCHLIST + SUCCESS,
          payload: response,
       });
     } 
     else{
        yield put({
          type: REMOVE_FROM_WATCHLIST + FAILD,
         payload: response.Message,
       });

       yield delay(3000);
       yield put({ type: STATE + CLEAR });

      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(silentAuctionSaga)]);
}
