import { combineReducers } from 'redux';
import Auth from './auth/reducer';
import Bid from './bid/reducer';
import Donation from './donation/reducer';
import LiveAuction from './live_auction/reducer';
import SilentAuction from './silent_auction/reducer';
export default combineReducers({
   Auth,
   Bid,
   Donation,
   LiveAuction,
   SilentAuction
  });