import { createStore, applyMiddleware } from "redux";

import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";

import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
//  import reduxlogger from "redux-logger";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const createStoreWithMiddleware = applyMiddleware(...middlewares);
let store = createStore(rootReducer, createStoreWithMiddleware);
let persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
export { store, persistor };
