
import "./App.css";
import { Provider } from 'react-redux';
import { store, persistor} from './redux/store';
import { PersistGate } from 'redux-persist/es/integration/react'  
import Routes from "./routes/router";
// import {useLocation,useRouteMatch } from "react-router-dom";
// import '../public/assets/css/bootstraps'
// import '../public/assets/css/style.css'

function App() {

  return (
    <Provider store={store}>
    <PersistGate 
    loading={<div>Loading...</div>}
  
    persistor={persistor}>
  <Routes/>
    </PersistGate>
  </Provider>
  );
}

export default App;
