import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import "./brands-logo.css";

import { Autoplay } from "swiper";
import { Link } from "react-router-dom";


function BrandsLogo ({Imgs}){
    return (
      <div className="container brand-section margin-top-neg-10">

    <Swiper

      
      slidesPerView={3}
      spaceBetween={30}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      breakpoints={{        
        390: {
          slidesPerView: Imgs && Imgs.length <= 3 ? Imgs.length : 3,
          spaceBetween: 40,
          autoplay: Imgs && Imgs.length > 3 ? {
            delay: 2500,
            disableOnInteraction: false,
          } : false
        },
        768: {
          slidesPerView: Imgs && Imgs.length <= 3 ? Imgs.length : 3,
          spaceBetween: 40,
          autoplay: Imgs && Imgs.length > 3 ? {
            delay: 2500,
            disableOnInteraction: false,
          } : false
        },
        1024: {          
          slidesPerView: Imgs && Imgs.length <= 6 ? Imgs.length : 6,
          spaceBetween: 0,
          autoplay: Imgs && Imgs.length > 6 ? {
            delay: 2500,
            disableOnInteraction: false,
          } : false

        },
      }}
      className="mySwiper"
    >
      {Imgs &&
     <div>
       {Imgs.length>0?Imgs.map((item,k)=>(

     <SwiperSlide key={k}>
        <div className="item">
              <Link class="brand-link-width" to="/" >
                <img className="brand-logo-size"
                  src={item.Url?item.Url:require("../../../images/brand-1.png")}
                  alt=""               
                />
              </Link>
        </div>
      </SwiperSlide>)):
     <SwiperSlide>
        <div className="item">
              <Link class="brand-link-width" to="/">
                <img className="brand-logo-size"
                  src={require("../../../images/brand-1.png")}
                  alt=""                  
                />
              </Link>
        </div>
      </SwiperSlide>}
     </div>
      }

    </Swiper>
      </div>
    );
  }


export default BrandsLogo;
