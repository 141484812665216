import React from "react";
import { useSelector } from "react-redux";
// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";
// // Import Swiper styles
// import "swiper/css";
import "./slider-home.css";

const SliderHome = ({Raised,Goal,ShowFundsRaised}) => {

  const Auth = useSelector((state) => state.Auth);

  //   useEffect(() => {}, []);
  // Create our number formatter.
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency: "USD",
  });
  return (
    <div className="container">
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
        data-interval="3000"
      >
        
        <div className="carousel-inner">
          <div className="carousel-item active">
            {Auth.auctionData && Auth.auctionData?.WebsiteBanner != null && <img
              className="d-block w-100"
              src={
                Auth.auctionData?.WebsiteBanner != null
                  ? Auth.auctionData?.WebsiteBanner.Url
                  : "/assets/images/no-img.png"
              }
              alt=""
            />}

            {Auth.auctionData && Auth.auctionData?.WebsiteBanner == null && <img
              className="d-block w-100 no-img"
              src="/assets/images/no-img.png"
              alt=""
            />}
           
          </div>          
        </div>        
      </div>


      {ShowFundsRaised && 
      <div className="inner">
        <div className="row row-fix justify-content-md-center">
          <div className="col-md-10 col-lg-7 col-xl-5 group-counter bg-white shadow-lg py-2 offSetCounter">
            {/* <!-- Linear progress bar--> */}

            
              <div className="progress-linear animated cs">
              <div className="progress-header">
                <p>
                  {"$"+formatter.format(Raised)} <span>raised</span>
                </p>
                <span className="progress-value">{"$"+formatter.format(Goal)} Goal</span>
              </div>
              <div className="progress-bar-linear-wrap">
                <div
                  className="progress-bar-linear"
                  style={{ width: Raised < Goal ? (Raised*100/Goal)+"%" : "100%" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>      
}

{!ShowFundsRaised && <div className="no-funds-raised-spacer"></div>}

    </div>
      
  );
};

export default SliderHome;
