import { _types } from "../types";
const { STATE, CLEAR, LIVE_ITEM,LIVE_ITEMS,LIVE_ITEMS_CATEGORY,LIVE_ITEM_CATEGORY,  SUCCESS } = _types;
const initState = {
  Live_isProgress: false,
  Live_isError: false,
  Live_errorText: "",
  Live_isSuccess: false,
  Live_successText: "",
  itemByCategory:null,
  itemsByCategory:null,
  item:null,
  items:null,
};
function liveReducer(state = initState, action) {
  switch (action.type) {
    case _types.REQUEST_PROGRESS:
      return {
        Live_isProgress: true,
      };

    case LIVE_ITEM + SUCCESS:
      return {
        ...state,
        Live_isProgress: false,
        Live_isError: false,
        Live_errorText: "",
        Live_successText: "",
        item:action.payload,
      };
      case LIVE_ITEMS + SUCCESS:
        return {
          ...state,
          Live_isProgress: false,
          Live_isError: false,
          Live_errorText: "",
          Live_successText: "",
          items:action.payload,
        };

        case LIVE_ITEM_CATEGORY + SUCCESS:
          return {
            ...state,
            Live_isProgress: false,
            Live_isError: false,
            Live_errorText: "",
            Live_successText: "",
            items:action.payload,
          };
          case LIVE_ITEMS_CATEGORY + SUCCESS:
            return {
              ...state,
              Live_isProgress: false,
              Live_isError: false,
              Live_errorText: "",
              Live_successText: "",
              itemsByCategory:action.payload,
            };
  
    case STATE + CLEAR:
      return {
        ...state,
        Live_isProgress: false,
        Live_isError: false,
        Live_errorText: "",
        Live_isSuccess: false,
        Live_successText: "",
      };
    default:
      return state;
  }
}

export default liveReducer;
