import { _types } from "../types";
const { REQ, SIGNIN, SIGNOUT, REGISTER,BIDDER_UPDATE,AUCTION,BIDDER,FORGET_PASSWORD,RESET_PASSWORD } = _types;
const actions = {
  signIn: (payload) =>
    // console.log(payload),
    ({
      type: SIGNIN + REQ,
      payload: payload,
    }),
    signOut: () =>
    // console.log(payload),
    ({
      type: SIGNOUT + REQ,
    }),
    register: (value) =>
    // console.log(payload),
    ({
      type: REGISTER + REQ,
      payload: value,
    }),
    updateUser: (value) =>
    // console.log(payload),
    ({
      type: BIDDER_UPDATE + REQ,
      payload: value,
    })
    ,
    auction: (shortCode) =>
    // console.log(payload),
    ({
      type: AUCTION + REQ,
      payload: shortCode,
    }),
    bidder: (value) =>
    // console.log(payload),
    ({
      type: BIDDER + REQ,
      payload: value,
    }),
    forgetPassword: (value) =>
    ({
      type: FORGET_PASSWORD + REQ,
      payload: value,
    }),
    resetPassword: (value) =>
    ({
      type: RESET_PASSWORD + REQ,
      payload: value,
    }),
};
export default actions;
