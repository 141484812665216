// App.js
// Kindacode.com
import { useEffect, useState } from "react";


const ScrollTop = () => {
  // The back-to-top button is hidden at the beginning
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };

  return (
    <>
      {showButton && (
        
        <div  onClick={scrollToTop} id="ui-to-top" className="ui-to-top fa fa-angle-up active"></div>
      )}
      
    </>
  );
};

export default ScrollTop;