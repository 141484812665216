import { all, fork, takeLatest, delay, put } from "redux-saga/effects";
import { _types } from "../types";
import _api from "./api";

const { DONATION, DONATIONS,DONATE, REQ, CLEAR, STATE, SUCCESS, FAILD } = _types;

export function* donationSaga() {
  //Donation Item
  yield takeLatest(DONATION + REQ, function* ({ payload }) {
    const response = yield _api.donationItem(payload);
    if (!response) {
      yield put({
        type: DONATION + FAILD,
        payload: response.error_description,
      });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: DONATION + SUCCESS,
        payload: response,
      });
    }
  });

  // Donation Items
  yield takeLatest(DONATIONS + REQ, function* ({ payload }) {
    const response = yield _api.allDonationItem(payload.auctionId,"");
    if (!response) {
      yield put({
        type: DONATIONS + FAILD,
        payload: response.error_description,
      });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: DONATIONS + SUCCESS,
        payload: response,
      });
    }
  });

  // Donate
  yield takeLatest(DONATE + REQ, function* ({ payload }) {
    const response = yield _api.donate(payload.data,payload.token);
    if (!response) {
      yield put({
        type: DONATE + FAILD,
        payload: response.error_description,
      });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      if(response.success){
      yield put({
        type: DONATE + SUCCESS,
        payload: response,
      });
    }else{     
       yield put({
      type: DONATE + FAILD,
      payload: response.error_description,
    });
    yield delay(3000);
    yield put({ type: STATE + CLEAR });}
    }
  });
}

export default function* rootSaga() {
  yield all([fork(donationSaga)]);
}
