import { _types } from "../types";
const { STATE, CLEAR, SILENT_ITEM,SILENT_ITEMS,SILENT_ITEMS_CATEGORY,SILENT_ITEM_CATEGORY,  SUCCESS, FAILD,  ADD_WATCHLIST, REMOVE_FROM_WATCHLIST } = _types;
const initState = {
  Silent_isProgress: false,
  Silent_isError: false,
  Silent_errorText: "",
  Silent_isSuccess: false,
  Silent_successText: "",
  itemByCategory:null,
  itemsByCategory:null,
  item:null,
  items:null,
};
function silentReducer(state = initState, action) {
  switch (action.type) {
    case _types.REQUEST_PROGRESS:
      return {
        Silent_isProgress: true,
      };

    case SILENT_ITEM + SUCCESS:
      return {
        ...state,
        Silent_isProgress: false,
        Silent_isError: false,
        Silent_errorText: "",
        Silent_successText: "",
        item:action.payload,
      };
      case SILENT_ITEMS + SUCCESS:
        return {
          ...state,
          Silent_isProgress: false,
          Silent_isError: false,
          Silent_errorText: "",
          Silent_successText: "",
          items:action.payload,
        };

        case SILENT_ITEM_CATEGORY + SUCCESS:
          return {
            ...state,
            Silent_isProgress: false,
            Silent_isError: false,
            Silent_errorText: "",
            Silent_successText: "",
            items:action.payload,
          };
          case SILENT_ITEMS_CATEGORY + SUCCESS:
            return {
              ...state,
              Silent_isProgress: false,
              Silent_isError: false,
              Silent_errorText: "",
              Silent_successText: "",
              itemsByCategory:action.payload,
            };
      
        case ADD_WATCHLIST + SUCCESS:
        return {
          ...state,
          isProgress: false,
          isError: false,
          isSuccess: true,
          errorText: "",
          Silent_successText: action.payload.Message,
        };

        case REMOVE_FROM_WATCHLIST + SUCCESS:
        return {
          ...state,
          isProgress: false,
          isError: false,
          isSuccess: true,
          errorText: "",
          Silent_successText: action.payload.Message,
        };

    case ADD_WATCHLIST + FAILD:
    return {
      ...state,
      isProgress: false,
      isSuccess: false,
      successText: "",
      isError: true,
      errorText: action.payload,
      };

    case REMOVE_FROM_WATCHLIST + FAILD:
    return {
      ...state,
      isProgress: false,
      isSuccess: false,
      successText: "",
      isError: true,
      errorText: action.payload,
    };
  
    case STATE + CLEAR:
      return {
        ...state,
        Silent_isProgress: false,
        Silent_isError: false,
        Silent_errorText: "",
        Silent_isSuccess: false,
        Silent_successText: "",
      };
    default:
      return state;
  }
}

export default silentReducer;
