export const _types = {
  // Global
  REQ: "_REQ",
  RES: "_RES",
  FAILD: "_FAILD",
  SUCCESS: "_SUCCESS",
  CLEAR: "_CLEARED",
  PROGRESS: "_PROGRESS",
  STATE: "STATE",

  // Auth
  SIGNIN: "SIGNIN",
  SIGNOUT: "SIGNOUT",
  REGISTER: "REGISTER",
  FORGET_PASSWORD:"FORGET_PASSWORD",
  RESET_PASSWORD:"RESET_PASSWORD",
  AUCTION: "AUCTION",

  // Silent Auction
  SILENT_ITEM: "SILENT_ITEM",
  SILENT_ITEMS: "SILENT_ITEMS",
  SILENT_ITEM_CATEGORY: "SILENT_ITEM_CATEGORY",
  SILENT_ITEMS_CATEGORY: "SILENT_ITEMS_CATEGORY",

  // Live Auction
  LIVE_ITEM: "LIVE_ITEM",
  LIVE_ITEMS: "LIVE_ITEMS",
  LIVE_ITEM_CATEGORY: "LIVE_ITEM_CATEGORY",
  LIVE_ITEMS_CATEGORY: "LIVE_ITEMS_CATEGORY",

  // Donation
  DONATION: "DONATION",
  DONATIONS: "DONATIONS",
  DONATE: "DONATE",

  // Bids
  MY_BIDS: "MY_BIDS",
  BID_ADD_CART: "BID_ADD_CART",
  BID_CART_ITEMS: "BID_CART_ITEMS",
  BID_CHECKOUT: "BID_CHECKOUT",
  BID_CHECKOUT_COMPLETE: "BID_CHECKOUT_COMPLETE",
  BIDDER:'BIDDER',
  BIDDER_UPDATE:'BIDDER_UPDATE',
  MY_BIDS_BY_TYPE: 'MY_BIDS_BY_TYPE',
  MY_BIDS_RECEIPT:'MY_BIDS_RECEIPT',

  //Watchlist
  ADD_WATCHLIST: "ADD_WATCHLIST",
  REMOVE_FROM_WATCHLIST: "REMOVE_FROM_WATCHLIST",
};
