
import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import bidSagas from './bid/saga';
import donationSagas from './donation/saga';
import live_auctionSagas from './live_auction/saga';
import silent_auctionSagas from './silent_auction/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    bidSagas(),
    donationSagas(),
    live_auctionSagas(),
    silent_auctionSagas(),
  ]);
  }