import { all, fork, takeLatest, delay, put } from "redux-saga/effects";
import { _types } from "../types";
import _api from "./api";

const {
  BID_ADD_CART,
  BID_CART_ITEMS,
  BID_CHECKOUT,
  BID_CHECKOUT_COMPLETE,
  MY_BIDS,
  REQ,
  CLEAR,
  STATE,
  SUCCESS,
  FAILD,
  MY_BIDS_BY_TYPE,
  MY_BIDS_RECEIPT,
} = _types;

export function* bidSaga() {
  //
  yield takeLatest(BID_ADD_CART + REQ, function* ({ payload }) {
    const response = yield _api.addBid(payload.data, payload.token);
    if (response) {
      if (
        response.Message === "Authorization has been denied for this request."
      ) {
        yield put({
          type: BID_ADD_CART + FAILD,
          payload: "Session Time Out",
        });
        yield delay(3000);
        yield put({ type: STATE + CLEAR });
      } else {
        yield put({
          type: BID_ADD_CART + SUCCESS,
          payload: response,
        });
      }
    } else {
      yield put({
        type: BID_ADD_CART + FAILD,
        payload: "Some thing went Wrong",
      });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    }
  });

  // Cart Item
  yield takeLatest(BID_CART_ITEMS + REQ, function* ({ payload }) {
    const response = yield _api.cartItems(payload.auctionId, payload.token);
    if (!response) {
      yield put({
        type: BID_CART_ITEMS + FAILD,
        payload: response.error_description,
      });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: BID_CART_ITEMS + SUCCESS,
        payload: response,
      });
    }
  });

  // Checkout
  yield takeLatest(BID_CHECKOUT + REQ, function* ({ payload }) {
    const response = yield _api.checkout(payload.id, payload.token);
    if (response) {
      yield put({
        type: BID_CHECKOUT + SUCCESS,
        payload: response,
      });
    }
  });
  // Checkout Complete
  yield takeLatest(BID_CHECKOUT_COMPLETE + REQ, function* ({ payload }) {
    const response = yield _api.checkoutComplete(payload.data, payload.token);
    if (response?.error) {
      yield put({
        type: BID_CHECKOUT_COMPLETE + FAILD,
        payload: response.error_description,
      });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: BID_CHECKOUT_COMPLETE + SUCCESS,
        payload: response,
      });
    }
  });

  // My Bid
  yield takeLatest(MY_BIDS + REQ, function* ({ payload }) {
    const response = yield _api.myBid(payload.auctionId, payload.token);
    if (!response) {
      yield put({ type: MY_BIDS + FAILD, payload: response.error_description });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: MY_BIDS + SUCCESS,
        payload: response,
      });
    }
  });

    //Bids by selected type
    yield takeLatest(MY_BIDS_BY_TYPE + REQ, function* ({ payload }) {
      const response = yield _api.myBidByType(payload);
      if (!response) {
        yield put({ type: MY_BIDS_BY_TYPE + FAILD, payload: response.error_description });
        yield delay(3000);
        yield put({ type: STATE + CLEAR });
      } else {
        yield put({
          type: MY_BIDS_BY_TYPE + SUCCESS,
          payload: response,
        });
      }
    });
  
  // My Bids receipt
  yield takeLatest(MY_BIDS_RECEIPT + REQ, function* ({ payload }) {
    const response = yield _api.myBidReceipt(payload);
    if (!response) {
      yield put({ type: MY_BIDS_RECEIPT + FAILD, payload: response.error_description });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: MY_BIDS_RECEIPT + SUCCESS,
        payload: response,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(bidSaga)]);
}
