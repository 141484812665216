
import axios from 'axios';
import { API_URL } from "../../configs/siteConfig";


const _api = {
    
    allSilentItems: async function (data,token) {
       return axios.get(API_URL+'SilentItem/All?auctionId='+data,{
          headers: { 'content-type': 'application/x-www-form-urlencoded' ,
          'Authorization': 'Bearer ' + token,
        },
      })
        .then(function (response) {
         return response?.data;
        })
        .catch(function (error) {
          return error?.response.data;
        });
    },
    allSilentItemCategories: async function (data,token) {
      return axios.get(API_URL+'SilentItemCategories/All?auctionId='+data,{
         headers: { 'content-type': 'application/x-www-form-urlencoded' ,
         'Authorization': 'Bearer ' + token,
       },
       })
       .then(function (response) {
        return response?.data;
       })
       .catch(function (error) {
         return error?.response.data;
       });
   },
    silentItem: async function (data) {
      return axios.get(`${API_URL}SilentItem/Get?auctionId=${data.auctionId}&itemId=${data.SilentID}`,{
         headers: { 'content-type': 'application/x-www-form-urlencoded' ,
         'Authorization': 'Bearer ' + data.token,
       },
       })
       .then(function (response) {
        if(data.afterFunction)
        {
          data.afterFunction();
        }
        return response?.data;
       })       
       .catch(function (error) {
         return error?.response.data;
       });
   },
   silentItemCategory: async function (data) {
    return axios.get(`${API_URL}SilentItem/ByCategory?auctionId=${data.auctionId}&categoryId=${data.categoryId}`,{
       headers: { 'content-type': 'application/x-www-form-urlencoded' ,
       'Authorization': 'Bearer ' + data.token,
     },
     })
     .then(function (response) {
      return response?.data;
     })
     .catch(function (error) {
       return error?.response.data;
     });
 },

 addToWatchlist: async function (data){
  return axios
    .post(API_URL + "Watchlist/AddToWatchlist?itemId=" + data.itemId, null, {
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + data.token,
      },
    })
    .then(function (response) {

      if(response?.data.Success && data.afterAction)
      {
        data.afterAction();
      }

      return response?.data;
    })
    .catch(function (error) {
      return error?.response.data;
    });
},

removeFromWatchlist: async function (data){
  return axios
    .post(API_URL + "Watchlist/RemoveFromWatchlist?itemId=" + data.itemId, null,{
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + data.token,
      },
    })
    .then(function (response) {

      if(response?.data.Success && data.afterAction)
      {
        data.afterAction();
      }
      
      return response?.data;
    })
    .catch(function (error) {
      return error?.response.data;
    });
},
 
 
}
export default _api;
