import React, { Suspense } from "react";
import { useSelector,  useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
useLocation
} from "react-router-dom";
import { appRoutes } from "./route";
import Header from "../Pages/components/Header/Header";
import Footer from "../Pages/components/Footer/Footer";
import Home from "../Pages/Home/Home";
import Auth_action from "../redux/auth/actions";

const PrivateRoute = () => {
  const isLoggedIn = useSelector((state) => state.Auth?.auth?.access_token);
  const time = useSelector((state) => state.Auth?.auth?.expireAt);
  const {pathname}=useLocation();
  const dispatch = useDispatch();
  const isExpire = time < Date.now() / 1000;
  if (isExpire) {
    dispatch(Auth_action.signOut());
  }
  if (isLoggedIn) {
    return (
      <Switch>
      
        {appRoutes.Private.map((route, idx) => (
          <Route exact={route.exact} key={idx} path={`${route.path}`}>
            <route.component />
          </Route>
        ))}
        <Route path="/:shortcode" >
          <Home/>
         </Route>
      </Switch>
    );
  } else {
    if(appRoutes.Private.find(item=>item.path===pathname)){
    return (
      <Redirect
        to={{
          pathname: "/signin",
        }}
      />
    );
  }
  else{
return (<Switch>
<Route path="/:shortcode" >
          <Home/>
         </Route>
</Switch>)
  }
}
};
export default function Routes() {
  // const prams = useLocation();
  // const rou = useParams();

  // console.log(prams);
  // console.log(rou);
  
  return (
    <Suspense
      fallback={
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
      }
    >
      <Router>
        <div className="page">
          <Header />
          <Switch>
         
         
         {/* <Route> */}
          {appRoutes.Public.map((route, idx) => (
            <Route exact={route.exact} key={idx} path={`${route.path}`}>
              <route.component />
            </Route>
          ))}  
       
          <PrivateRoute />
          <Route path="/:shortcode" >
          <Home/>
         </Route>
          {/* <Route path="*" >
          <Home/>
         </Route>  */}
   </Switch>
          <Footer />
        </div>
      </Router>
    </Suspense>
  );
}
