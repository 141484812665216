
import axios from 'axios';

import { API_URL } from "../../configs/siteConfig";
// import SuperFetch from "../../configs/superFetch";
// import qs from 'qs';

const _api = {
  allLiveItems: async function (data,token) {
    // console.log(data)
    return axios.get(API_URL+'LiveItem/All?auctionId='+data,{
       headers: { 'content-type': 'application/json' ,
       'Authorization': 'Bearer ' + token,
     },
     })
     .then(function (response) {
      return response?.data;
     })
     .catch(function (error) {
       return error?.response.data;
     });
 },
 allLiveItemCategories: async function (data,token) {
   return axios.get(API_URL+'LiveItemCategories/All?auctionId='+data,{
      headers: { 'content-type': 'application/x-www-form-urlencoded' ,
      'Authorization': 'Bearer ' + token,
    },
    })
    .then(function (response) {
     return response?.data;
    })
    .catch(function (error) {
      return error?.response.data;
    });
},
 LiveItem: async function (data) {
   return axios.get(`${API_URL}LiveItem/Get?auctionId=${data.auctionId}&itemId=${data.liveID}`,{
      headers: { 'content-type': 'application/x-www-form-urlencoded' ,
      'Authorization': 'Bearer ' + data.token,
    },
    })
    .then(function (response) {
      if(data.afterFunction)
      {
        data.afterFunction();
      }

     return response?.data;
    })
    .catch(function (error) {
      return error?.response.data;
    });
},
LiveItemCategory: async function (data) {
 return axios.get(`${API_URL}LiveItem/ByCategory?auctionId=${data.auctionId}&categoryId=${data.categoryId}`,{
    headers: { 'content-type': 'application/x-www-form-urlencoded' ,
    'Authorization': 'Bearer ' + data.token,
  },
  })
  .then(function (response) {
   return response?.data;
  })
  .catch(function (error) {
    return error?.response.data;
  });
}
}
export default _api;
