import { all, fork, takeLatest, delay, put } from "redux-saga/effects";
import { _types } from "../types";
import _api from "./api";

const { LIVE_ITEM,LIVE_ITEMS,LIVE_ITEMS_CATEGORY,LIVE_ITEM_CATEGORY, REQ, CLEAR, STATE, SUCCESS, FAILD } = _types;

export function* liveAuctionSaga() {

  // Live Item
  yield takeLatest(LIVE_ITEM + REQ, function* ({ payload }) {
    const response = yield _api.LiveItem(payload)
    if (!response) {
      yield put({ type: LIVE_ITEM + FAILD, payload: response.error_description });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: LIVE_ITEM + SUCCESS,
        payload: response,
      });
    }
  });

    // Live Items
  yield takeLatest(LIVE_ITEMS + REQ, function* ({ payload }) {
    const response = yield _api.allLiveItems(payload.auctionId,payload.token)
    if (!response) {
      yield put({ type: LIVE_ITEMS + FAILD, payload: response.error_description });
      yield delay(3000);
      yield put({ type: STATE + CLEAR });
    } else {
      yield put({
        type: LIVE_ITEMS + SUCCESS,
        payload: response,
      });
    }
  });

  // Live item Category
     yield takeLatest(LIVE_ITEM_CATEGORY + REQ, function* ({ payload }) {
      const response = yield _api.LiveItemCategory(payload)
      if (!response) {
        yield put({ type: LIVE_ITEM_CATEGORY + FAILD, payload: response.error_description });
        yield delay(3000);
        yield put({ type: STATE + CLEAR });
      } else {
        yield put({
          type: LIVE_ITEM_CATEGORY + SUCCESS,
          payload: response,
        });
      }
    });

      // Live item Categories
      yield takeLatest(LIVE_ITEMS_CATEGORY + REQ, function* ({ payload }) {
        const response = yield _api.allLiveItemCategories(payload.auctionId,"")
        if (!response) {
          yield put({ type: LIVE_ITEMS_CATEGORY + FAILD, payload: response.error_description });
          yield delay(3000);
          yield put({ type: STATE + CLEAR });
        } else {
          yield put({
            type: LIVE_ITEMS_CATEGORY + SUCCESS,
            payload: response,
          });
        }
      });
}

export default function* rootSaga() {
  yield all([fork(liveAuctionSaga)]);
}
