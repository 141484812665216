import { _types } from "../types";
const { STATE, CLEAR, DONATION, DONATIONS,DONATE, SUCCESS,FAILD } = _types;
const initState = {
  donation_isProgress: false,
  donation_isError: false,
  donation_errorText: "",
  isSuccess: false,
  donation_successText: "",
  item: null,
  items: null,
};
function donationReducer(state = initState, action) {
  switch (action.type) {
    case _types.REQUEST_PROGRESS:
      return {
        donation_isProgress: true,
      };

    case DONATION + SUCCESS:
      return {
        ...state,
        donation_isProgress: false,
        donation_isError: false,
        donation_errorText: "",
        donation_successText: "",
        item: action.payload,
      };
    case DONATIONS + SUCCESS:
      return {
        ...state,
        donation_isProgress: false,
        donation_isError: false,
        isSuccess: false,
        donation_errorText: "",
        donation_successText: "",
        items: action.payload,
      };
    case DONATE + SUCCESS:
      return {
        ...state,
        donation_isProgress: false,
        donation_isError: false,
        isSuccess: true,
        donation_errorText: "",
        donation_successText: action.payload,
      };
    case DONATE + FAILD:
      return {
        ...state,
        donation_isProgress: false,
        donation_isError: true,
        isSuccess: false,
        donation_errorText: action.payload,
        donation_successText: "",
      };

    case STATE + CLEAR:
      return {
        ...state,
        donation_isProgress: false,
        donation_isError: false,
        donation_errorText: "",
        isSuccess: false,
        donation_successText: "",
      };
    default:
      return state;
  }
}

export default donationReducer;
