import { _types } from "../types";
const {
  STATE,
  CLEAR,
  FAILD,
  BID_ADD_CART,
  BID_CART_ITEMS,
  BID_CHECKOUT,
  BID_CHECKOUT_COMPLETE,
  MY_BIDS,
  SUCCESS,
  MY_BIDS_BY_TYPE,
  MY_BIDS_RECEIPT,
} = _types;
const initState = {
  Bid_isProgress: false,
  myBids: null,
  cartItem: null,
  checkout_item:null,
  Bid_Bid_isError: false,
  Bid_errorText: "",
  Bid_isSuccess: false,
  Bid_successText: "",
};
function bidReducer(state = initState, action) {
  switch (action.type) {
    case _types.REQUEST_PROGRESS:
      return {
        Bid_isProgress: true,
      };

    case BID_ADD_CART + SUCCESS:
      return {
        ...state,
        Bid_isProgress: false,
        Bid_isError: false,
        Bid_errorText: "",
        Bid_isSuccess: true,
        Bid_successText: action.payload,
      };
    case MY_BIDS + SUCCESS:
      return {
        ...state,
        Bid_isProgress: false,
        Bid_isError: false,
        Bid_isSuccess: false,
        Bid_errorText: "",
        Bid_successText: "",
        myBids: action.payload,
      };

      case MY_BIDS_BY_TYPE + SUCCESS:
      return {
        ...state,
        Bid_isProgress: false,
        Bid_isError: false,
        Bid_isSuccess: false,
        Bid_errorText: "",
        Bid_successText: "",
        myBids: action.payload,
      };
    
    case MY_BIDS_RECEIPT + SUCCESS:
      return {
        ...state,
        Bid_isProgress: false,
        Bid_isError: false,
        Bid_isSuccess: false,
        Bid_errorText: "",
        Bid_successText: "",
      };


    case BID_CART_ITEMS + SUCCESS:
      return {
        ...state,
        Bid_isProgress: false,
        Bid_isError: false,
        Bid_isSuccess: false,
        Bid_errorText: "",
        Bid_successText: "",
        cartItem: action.payload,
      };

    case BID_CHECKOUT + SUCCESS:
      return {
        ...state,
        Bid_isProgress: false,
        Bid_isError: false,
        Bid_errorText: "",
        Bid_isSuccess: false,
        Bid_successText: "",
        checkout_item:action.payload,
      };
    case BID_CHECKOUT_COMPLETE + SUCCESS:
      return {
        ...state,
        Bid_isProgress: false,
        Bid_isError: false,
        Bid_errorText: "",
        Bid_isSuccess: true,
        Bid_successText: action.payload,
      };

    case BID_CHECKOUT_COMPLETE + FAILD:
      return {
        ...state,
        Bid_isProgress: false,
        Bid_isError: true,
        Bid_errorText: action.payload,
        Bid_isSuccess: false,
        Bid_successText: "",
      };
    case BID_ADD_CART + FAILD:
      return {
        ...state,
        Bid_isProgress: false,
        Bid_isError: true,
        Bid_errorText: action.payload,
        Bid_isSuccess: false,
        Bid_successText: "",
      };

      case MY_BIDS_BY_TYPE + FAILD:
      return {
        ...state,
        Bid_isProgress: false,
        Bid_isError: true,
        Bid_errorText: action.payload,
        Bid_isSuccess: false,
        Bid_successText: "",
      };
    
    case MY_BIDS_RECEIPT + FAILD:
      return {
        ...state,
        Bid_isProgress: false,
        Bid_isError: true,
        Bid_errorText: action.payload,
        Bid_isSuccess: false,
        Bid_successText: "",
      };

    case STATE + CLEAR:
      return {
        ...state,
        Bid_isProgress: false,
        Bid_isError: false,
        Bid_errorText: "",
        Bid_isSuccess: false,
        Bid_successText: "",
      };
    default:
      return state;
  }
}

export default bidReducer;
