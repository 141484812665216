import { lazy } from "react";

export const appRoutes = {



  //   Declare Private Route Here
  Private: [
   
    {
      path: "/my-profile",
      component: lazy(() => import("../../src/Pages/MyProfile/MyProfile")),
    },
    {
      path: "/my-account",
      component: lazy(() => import("../../src/Pages/MyAccount/MyAccount")),
    },
    {
      path: "/my-bids",
      component: lazy(() => import("../../src/Pages/MyBids/mybids")),
     
    },
    
     {
       path: "/checkout",
       component: lazy(() => import("../../src/Pages/Checkout/Checkout"))
     },
    {
      path:"/checkout-2",
      component: lazy(() => import("../../src/Pages/Checkout2/Checkout2"))
    },
    // {
    //   path: "/",
    //   component: lazy(() => import("../../src/Pages/Home/Home")),       
    //    exact:true
    // },
       {
         path: "/:shortcode",
         component: lazy(() => import("../../src/Pages/Home/Home")),        
       }
  ],

    // Declare Public Route Here
    Public: [
      {
        path: "/donation/:shortcode",
        component: lazy(() => import("../../src/Pages/Donation/Donation")),
      },
      {
        path: "/cart/:shortcode",
        component: lazy(() => import("../../src/Pages/Cart/Cart")),
      },      
      {
        path:"/reset-password/:token",
        component: lazy(() => import("../../src/Pages/ResetPassword/ResetPassword"))
      },    
      // Landing Page
      {
        path: "/about/:shortcode",
        component: lazy(() => import("../../src/Pages/About/About")),
      
      },
      {
        path:"/terms-and-conditions",
        component: lazy(() => import("../../src/Pages/TermsAndConditions/TermsAndConditions"))
      },
      {
        path: "/faq",
        component: lazy(() => import("../../src/Pages/Faq/Faq"))
      },
      
      {
        path: "/signin",
        component: lazy(() => import("../../src/Pages/Signin/Signin"))
      },
      {
          path:"/register",
          component: lazy(() => import("../../src/Pages/Register/Register"))
        },
        {
          path: "/forgot-password" ,
          component: lazy(() => import("../../src/Pages/ForgotPassword/ForgotPassword"))
        },
        {
          path: "/live-auction/detail/:liveID/:shortcode",
          component: lazy(() => import("../../src/Pages/LiveAuctionDetail/liveAuctionDetail"))
        },
        /*{
          path: "/live-auction/:CategoryID",
          component: lazy(() => import("../../src/Pages/LiveAuction/LiveAuction")),
     
        },
    */
        {
          path: "/live-auction/:shortcode",
          component: lazy(() => import("../../src/Pages/LiveAuction/LiveAuction"))
        },
       
        {
          path: "/silent-auction/detail/:SilentID/:shortcode",
          component: lazy(() => import("../../src/Pages/SilentAuctionDetail/SilentAuctionDetail")),
        },
        /*
        {
          path: "/silent-auction/:CategoryID",
          component: lazy(() => import("../../src/Pages/SilentAuction/SilentAuction")),
        },*/
        {
          path: "/silent-auction/:shortcode",
          component: lazy(() => import("../../src/Pages/SilentAuction/SilentAuction")),
        },
        {
          path:"/donate/now/:id",
          component: lazy(() => import("../../src/Pages/DonateNow/DonateNow"))
        },
        {
          path:"/donate/:shortcode",
          component: lazy(() => import("../../src/Pages/Donate/Donate"))
        },
       
      // {
      //   path: "/:shortcode",
      //   component: lazy(() => import("../../src/Pages/Home/Home")),
      //   // exact: true,
      // },
      {
        path: "/",
        component: lazy(() => import("../../src/Pages/Home/Home")),       
         exact:true
      },
    
    ],
};
