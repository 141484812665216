import { _types } from "../types";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
const {
  STATE,
  CLEAR,
  FAILD,
  SIGNIN,
  SIGNOUT,
  AUCTION,
  BIDDER,
  REGISTER,
  BIDDER_UPDATE,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  SUCCESS,
} = _types;
const initState = {
  isProgress: false,
  auth: null,
  isError: false,
  errorText: "",
  isSuccess: false,
  successText: "",
  register: "",
  profile: "",
};
function authReducer(state = initState, action) {
  switch (action.type) {
    case _types.REQUEST_PROGRESS:
      return {
        isProgress: true,
      };

    case SIGNIN + SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: "",
        successText: "",
        auth: {
          ...action.payload,
          expireAt: Date.now() / 1000 + action.payload.expires_in,
        },
      };
    case BIDDER + SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: "",
        successText: "",
        profile: action.payload,
      };
    case REGISTER + SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        isSuccess: true,
        errorText: "",
        successText: action.payload.Message,
        register: action.payload,
      };

    case BIDDER_UPDATE + SUCCESS:
      return {
        //...this.getState(),
        isProgress: false,
        isError: false,
        errorText: "",
        successText: "",        
        profile: action.payload.Success ?? action.payload.Profile,
        ProfileUpdateMessage: action.payload.Message,
        ProfileUpdateSuccess: action.payload.Success
      };       

    case FORGET_PASSWORD + SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        isSuccess: true,
        errorText: "",
        forgotPasswordSuccess: true,
        successText: action.payload.Message,
      };
    case RESET_PASSWORD + SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        isSuccess: true,
        isPasswordReset: true,
        errorText: "",
        successText: action.payload.Message,
      };
    case AUCTION + SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        isSuccess: true,
        errorText: "",
        successText: "",
        auctionData: action.payload,
      };

    case REGISTER + FAILD:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: "",
        isError: true,
        errorText: action.payload,
      };

    case SIGNIN + FAILD:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: "",
        isError: true,
        errorText: action.payload,
      };

    case FORGET_PASSWORD + FAILD:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: "",
        isError: true,
        errorText: action.payload,
      };
    case RESET_PASSWORD + FAILD:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: "",
        isError: true,
        errorText: action.payload,
      };

    case SIGNOUT:
      //return initState;
      return {
        ...initState,
        isProgress: false,
        isError: false,
        errorText: "",
        successText: "",
        auctionData: state.auctionData
      };

    case STATE + CLEAR:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: "",
        isSuccess: false,
        successText: "",
      };
    default:
      return state;
  }
}
const persistConfig = {
  key: "authReducer",
  storage: storage,
  blacklist: [
    "isProgress",
    "isError",
    "register",
    "errorText",
    "isSuccess",
    "successText",
  ],
  whitelist: ["auth", "auctionData", "profile"],
};
export default persistReducer(persistConfig, authReducer);
